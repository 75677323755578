<template>
	<div>
		<!-- Header -->
		<div
			class="headline font-weight-bold mb-7"
			v-html="
				$t('app.student_interviews', {
					user_name: fullName(current_student.user),
				})
			"
		></div>

		<!-- Interviews list -->
		<v-card v-if="list && list.length" outlined>
			<v-data-table
				:headers="headers"
				:calculate-widths="true"
				:items="items"
				:single-expand="true"
				:expanded.sync="expanded"
				item-key="id"
				show-expand
				hide-default-footer
				hide-default-header
				class="wr_grey_1 subtitle-1"
			>
				<template v-slot:header="{ props }">
					<th
						v-for="(head, index) in props.headers"
						:key="index"
						class="font-weight-bold px-4 py-2"
						align="start"
					>
						{{ $t(head.text) }}
					</th>
				</template>

				<template v-slot:expanded-item="{ headers, item }">
					<td :colspan="headers.length">
						<Interview
							:prop_interview="item"
							:panel="[]"
							:can_modify="false"
						></Interview>
					</td>
				</template>
			</v-data-table>
		</v-card>

		<!-- If no interviews are created -->
		<NoData
			v-else
			heading="app.no_interviews_yet"
			:show_action="false"
		></NoData>
	</div>
</template>

<script>
import AppTable from "@/components/ui/AppTable.vue";
import moment from "moment";
import NoData from "@/components/shared/NoData.vue";
import { mapState, mapGetters } from "vuex";
import Interview from "@/components/shared/tools/interview/Index.vue";

export default {
	name: "StudentInterviews",

	data() {
		return {
			moment,
			list: null,
			expanded: [],
		};
	},

	components: {
		AppTable,
		NoData,
		Interview,
	},

	mounted() {
		this.getInterviews();
	},

	computed: {
		...mapState({
			current_student: (state) => state.student.current_student,
		}),

		...mapGetters({
			fullName: "user/fullName",
		}),

		headers() {
			return [
				{ text: "app.interview_no", value: "s_no" },
				{ text: "app.interviewees", value: "interviewees_string" },
				{ text: "app.interview_date", value: "interview_date_string" },
				{ text: "app.goal", value: "goal" },
				{ text: "Expand", value: "data-table-expand" },
			];
		},

		items() {
			let result = [];
			let sorted_result = _.sortBy(this.list, [
				function(o) {
					return o.id;
				},
			]);

			sorted_result.forEach((item, index) => {
				item.s_no = index + 1;
				item.interviewees_string = item.interviewees
					.map((item) => item.name)
					.filter((n) => n)
					.join(", ");
				item.interview_date_string = moment(item.date).format("DD/MM/YYYY");
				result.push(item);
			});

			return result;
		},

		actions() {
			return [
				{
					icon: this.$vuetify.rtl ? "left_arrow" : "right_arrow",
					color: "success",
					event_name: "explore",
				},
			];
		},
	},

	methods: {
		async getInterviews() {
			try {
				this.$loader.start();

				this.list = await this.$store.dispatch("interview/listOfStudent", {
					user_id: this.$route.params.id,
				});
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		exploreInterview(interview_id) {
			try {
				this.$loader.start();

				this.$router.push({
					name: "MentorInterview",
					params: {
						id: interview_id,
					},
				});
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},
};
</script>
