<template>
	<div v-if="interview" class="my-5 wr_grey_1" align="center">
		<v-expansion-panels v-model="panel" multiple accordion tile class="panel">
			<v-expansion-panel
				v-for="(section, index) in sections"
				:key="'section' + index"
			>
				<v-expansion-panel-header
					class="info--text subtitle-1 font-weight-bold"
					>{{ $t(section.header) }}</v-expansion-panel-header
				>
				<v-expansion-panel-content class="text-start">
					<!--------------------------------------------
						INTERVIEWEES
					 -------------------------------------------->
					<table v-if="section.type === 'interviewees'">
						<!-- Static Labels -->
						<tr>
							<th>
								<TextBox
									color="black"
									:prop_value="$t('app.name_of_interviewee')"
									:readonly="true"
								></TextBox>
							</th>
							<th>
								<TextBox
									color="black"
									:prop_value="$t('app.role_of_interviewee')"
									:readonly="true"
								></TextBox>
							</th>
						</tr>
						<!-- Text fields to enter interviewee details -->
						<tr
							v-for="(item, intervieweeIndex) in interview.interviewees"
							:key="section.type + intervieweeIndex"
						>
							<td>
								<TextBox
									color="black"
									bgColor="greyLighten4"
									v-model="item.name"
									:prop_value="item.name"
									:readonly="!can_modify"
									placeholder="app.placeholder.type_name_here"
									@update-value="updateInterview"
								></TextBox>
							</td>
							<td>
								<TextBox
									color="black"
									bgColor="greyLighten4"
									v-model="item.role"
									:prop_value="item.role"
									:readonly="!can_modify"
									placeholder="app.placeholder.type_role_here"
									:show_append_icon="
										interview.interviewees.length > 1 && can_modify
									"
									@update-value="updateInterview"
									@execute-append-action="removeInterviewee(intervieweeIndex)"
								></TextBox>
							</td>
						</tr>
					</table>

					<!--------------------------------------------
						INTERVIEW DETAILS
					 -------------------------------------------->
					<table v-if="section.type === 'interview_details'">
						<!-- Interview Date -->
						<tr>
							<td>
								<TextBox
									color="black"
									:prop_value="$t('app.date_of_interview')"
									:readonly="true"
								></TextBox>
							</td>
							<td>
								<!-- Use "actual_date" for plan_interview  -->
								<DatePicker
									v-if="isPlanInterviewTab"
									v-model="interview.actual_date"
									:prop_date="interview.actual_date"
									:solo="true"
									:disabled="!can_modify"
									:append_icon="$vuetify.icons.values.calendar"
									@update-value="updateInterview"
									:date_condition="'after-today'"
								></DatePicker>

								<!-- Use "date" for document_interview  -->
								<DatePicker
									v-else
									v-model="interview.date"
									:solo="true"
									:prop_date="interview.date"
									:append_icon="$vuetify.icons.values.calendar"
									@update-value="updateInterview"
								></DatePicker>
							</td>
						</tr>

						<!-- Interview Goal -->
						<tr>
							<td>
								<TextBox
									color="black"
									:prop_value="$t('app.goal_of_interview')"
									:readonly="true"
								></TextBox>
							</td>
							<td>
								<!-- for goal field -->
								<TextBox
									color="black"
									bgColor="greyLighten4"
									type="v-textarea"
									placeholder="app.placeholder.type_goal_here"
									v-model="interview.goal"
									:readonly="!can_modify"
									:prop_value="interview.goal"
									@update-value="updateInterview"
								></TextBox>
							</td>
						</tr>
					</table>

					<!--------------------------------------------
						INTERVIEW QUESTIONS
					 -------------------------------------------->
					<table v-if="section.type === 'interview_questions'">
						<tr
							v-for="(item, queIndex) in interview.questions"
							:key="section.type + queIndex"
						>
							<td colspan="2">
								<TextBox
									color="black"
									bgColor="greyLighten4"
									type="v-textarea"
									:rows="2"
									v-model="item.title"
									:readonly="!can_modify"
									:prop_value="item.title"
									placeholder="app.placeholder.type_your_question_here"
									@update-value="updateInterview"
								></TextBox>
							</td>
							<td v-if="!isPlanInterviewTab">
								<TextBox
									color="black"
									bgColor="greyLighten4"
									type="v-textarea"
									v-model="item.value"
									:prop_value="item.value"
									:rows="2"
									placeholder="app.placeholder.write_answer_to_question"
									:show_append_icon="interview.questions.length > 2"
									@update-value="updateInterview"
									@execute-append-action="removeQuestion(queIndex)"
									:readonly="isPlanInterviewTab"
								></TextBox>
							</td>
						</tr>
					</table>

					<!--------------------------------------------
						ADDITIONAL DETAILS
					 -------------------------------------------->
					<table v-if="section.type === 'additional_details'">
						<tr
							v-for="(item, additionalIndex) in interview.additional_details"
							:key="section.type + additionalIndex"
						>
							<td>
								<TextBox
									color="black"
									bgColor="greyLighten4"
									type="v-textarea"
									v-model="item.title"
									:readonly="!can_modify"
									:prop_value="$t(item.title)"
									placeholder="app.placeholder.type_additional_detail_title_herel"
									@update-value="updateInterview"
								></TextBox>
							</td>
							<td>
								<TextBox
									color="black"
									bgColor="greyLighten4"
									type="v-textarea"
									v-model="item.value"
									:readonly="!can_modify"
									:prop_value="item.value"
									placeholder="app.placeholder.type_additional_detail_title_herel"
									:show_append_icon="
										interview.additional_details.length > 2 && can_modify
									"
									@update-value="updateInterview"
									@execute-append-action="
										removeAdditionalDetail(additionalIndex)
									"
								></TextBox>
							</td>
						</tr>
					</table>

					<!--------------------------------------------
						ADD DETAILS BUTTON FOR SPECIFIC SECTIONS
					 -------------------------------------------->
					<AppButton
						v-if="section.type !== 'interview_details' && can_modify"
						:label="
							section.type === 'interviewees'
								? 'app.add_interviewee'
								: section.type === 'interview_questions'
								? 'app.add_question'
								: section.type === 'additional_details'
								? 'app.add_row'
								: ''
						"
						:text="true"
						color="error"
						icon="plus"
						:class="['mt-4']"
						@click="handleDetailsAddition(section.type)"
					></AppButton>
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>
	</div>
</template>

<script>
import DatePicker from "@/components/plugins/DatePicker";
import TextBox from "@/components/plugins/TextBox.vue";
import { mapState } from "vuex";

export default {
	name: "Interview",

	props: {
		interview_id: {
			required: false,
		},
		prop_interview: {
			required: false,
		},
		can_modify: {
			default: true,
		},
		panel: {
			type: Array,
			default: () => [0, 1, 2, 3],
		},
	},

	data() {
		return {
			sections: [
				{
					type: "interviewees",
					header: "app.interviewees",
				},
				{
					type: "interview_details",
					header: "app.interview_details",
				},
				{
					type: "interview_questions",
					header: "app.interview_questions",
				},
				{
					type: "additional_details",
					header: "app.additional_details",
				},
			],
		};
	},

	components: {
		DatePicker,
		TextBox,
	},

	computed: {
		...mapState({
			current_tab: (state) => state.interview.current_tab,
		}),

		interview() {
			if (this.prop_interview) return this.prop_interview;

			return this.$store.getters["interview/getInterview"](
				this.interview_id ? this.interview_id : this.$route.params.id,
			);
		},

		isPlanInterviewTab() {
			return this.current_tab === "plan-interview";
		},
	},

	methods: {
		handleDetailsAddition(type) {
			switch (type) {
				case "interviewees":
					this.addInterviewee();
					break;
				case "interview_questions":
					this.addQuestion();
					break;
				case "additional_details":
					this.addAdditionalDetail();
					break;
			}
		},

		async updateInterview() {
			try {
				await this.$store.dispatch("interview/update", {
					interview_id: this.interview.id,
					data: this.interview,
				});
			} catch (error) {
				this.$announce.error(error);
			}
		},

		addInterviewee() {
			try {
				// Push in local
				this.interview.interviewees.push({
					name: null,
					role: null,
				});

				// Update at server
				this.updateInterview();
			} catch (error) {
				this.$announce.error(error);
			}
		},

		addQuestion() {
			try {
				// Push in local
				this.interview.questions.push({
					title: "Question Title",
					value: null,
				});

				// Update at server
				this.updateInterview();
			} catch (error) {
				this.$announce.error(error);
			}
		},

		addAdditionalDetail() {
			try {
				// Push in local
				this.interview.additional_details.push({
					title: null,
					value: null,
				});

				// Update at server
				this.updateInterview();
			} catch (error) {
				this.$announce.error(error);
			}
		},

		removeInterviewee(index) {
			try {
				// Remove from local
				this.interview.interviewees.splice(index, 1);

				// Update at server
				this.updateInterview();
			} catch (error) {
				this.$announce.error(error);
			}
		},

		removeQuestion(index) {
			try {
				// Remove from local
				this.interview.questions.splice(index, 1);

				// Update at server
				this.updateInterview();
			} catch (error) {
				this.$announce.error(error);
			}
		},

		removeAdditionalDetail(index) {
			try {
				// remove from local
				this.interview.additional_details.splice(index, 1);

				// Update at server
				this.updateInterview();
			} catch (error) {
				this.$announce.error(error);
			}
		},
	},
};
</script>

<style scoped>
.panel {
	width: 1000px;
}
table {
	border-collapse: collapse;
	width: 100%;
}
tr {
	width: 100%;
}
td,
th {
	border: 1px solid;
	width: 50%;
}
</style>
